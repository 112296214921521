/*Global
===========*/
:root{
--text_color:#e6e3e3;
--yellow:yellow;
--red:rgb(212, 22, 22);
--main_black: rgb(28, 29, 30);
--teal: rgb(0, 178, 202);
--grey:rgb(71, 71, 71);
--green:rgb(51, 255, 0);
--yellowtoteal: -webkit-linear-gradient(left, yellow 60%, rgb(0, 178, 202) 60%);
--blue:rgb(21, 21, 250);
--orange: rgb(255, 145, 0);
--pink:rgb(253, 112, 135);
--purple: rgb(171, 10, 185);
--text_size: 1.2em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main_black) !important;
}

html {
background-color: var(--main_black) ;  
}
.pink{
  color:var(--pink);
}
.orange{
  color:var(--orange);
}
.green{
  color:var(--green);
}
.teal{
  color:var(--teal);
}

.yellow{
  color:var(--yellow) !important;
}

.red{
  color:var(--red);
}

.pinkOnHover:hover{
  color:var(--pink);
} 

.greenOnHover:hover{
  color:var(--green)
} 

.purpleOnHover:hover{
  color:var(--purple);
}
.blueOnHover:hover{
  color:var(--blue)
}
.yellowOnHover:hover{
  color:var(--yellow);
}
.orangeOnHover:hover{
  color:var(--orange);
}
.tealOnHover:hover{
  color:var(--teal)
}

.greenButton{
  background-color: rgb(30, 189, 30);
  border-color:transparent !important;
  color:white ;
  border-radius: 5px;
  padding: 0 20px;
  cursor:pointer;
}

.greenButton:hover{
  background-color:green;
  color:lightgray;
}
section, nav, footer{
  background-color: var(--main_black);
  color:var(--text_color);
}

.section{
  width:75%;
  margin:0 auto;
  margin-bottom:50px;
}
section:first-of-type{
  margin-top:20px;
}
@media (max-width:750px){
  section{
    width:85%;
  }
}
p{
  font-size:var(--text_size);
}
h1{
  color:var(--text_color);
  font-size:10em !important;
  font-weight:900 !important;
  text-shadow:18px 17px #282525;
}

@media (max-width:750px){

  h1{
      font-size: 3.5em !important;
  }
}
.mainH2{
  font-weight:900 !important;
  font-size:4em !important;
  margin-bottom:.8em !important;
  text-shadow:18px 10px #282525;
}

@media (max-width:750px){
  h2{
    font-size:3em !important;
  }
}
i{
  font-weight:700;
  font-size:2em !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr{
  background-color: var(--text_color) ;

}

button{
  font-weight:bold !important;
}
.text{
  font-size: 4.5em;
}

.row{
  width:100%;
  margin:0;
}

.colorHover{
  transition: 500ms;
  color:grey;
}

.sectionMain{
  font-weight: 900;
  font-size:1.5em;
  text-shadow: 1px 2px  black;
}

@media (max-width:750px){
  .sectionMain{
    font-size:1.4em;
  }
}
.hiddenLink{
  color:var(--text_color);
  text-decoration: none;
  margin-right: 5px;
  cursor:pointer;
}


/*End global*/

/*Main Nav
=======*/
.navItem{
  font-weight:900;
  font-size:1.2em;
}
.title hr{
    background-color:#E8C932;
    padding:4px;
    opacity: 1;
    width: 4em;
    margin-top: 0;
    margin:0 auto;
}
.scene {
  margin:0 auto;
  width:160px;
  height:140px;
  position: relative;
  perspective: 1100px;
  animation-name: floating;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function:ease-in-out ;
}

.carousel{
  width:100%;
  height:100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
  animation: circling 35s infinite linear;
}

.carouselReverse{
  animation-direction: reverse;
}

.face{
  position:absolute;
  width:140px;
  height:120px;
  top:20px;
  opacity:.7;
  transition: transform 1s, opacity 1s;
}

.face:hover{
  opacity: 1 !important;
}

.face:nth-child(1){
  transform:rotateY(0deg)  translateZ(180px);
  animation: flicker infinite 4s 7s steps(4, end);
  

}
.face:nth-child(2){
  transform:rotateY(51deg)  translateZ(180px);
  animation: flicker infinite 4s 10s steps(4, start);

}
.face:nth-child(3){
  transform:rotateY(104deg)  translateZ(180px);
  animation: flicker2 infinite 3s 9s steps(4);
}
.face:nth-child(4){
  transform:rotateY(156deg)  translateZ(180px);
  animation: flicker infinite 4s 12s steps(4, end);
}
.face:nth-child(5){
  transform:rotateY(208deg)  translateZ(180px);
  animation: flicker infinite 3s 11s steps(4);
}
.face:nth-child(6){
  transform:rotateY(258deg)  translateZ(180px);
  animation: flicker infinite 4s 13s steps(5);
}
.face:nth-child(7){
  transform:rotateY(308deg)  translateZ(180px);
  animation: flicker2 infinite 4s 9s steps(5);

}



.carouselShadow{
  width: 100px;
  height:100px;
  background-color: black;
  margin: 0 auto;
  border-radius: 50%;
  animation-name: growing;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function:ease-in-out ;
}

.radioOption, input[type="radio"]{
  cursor:pointer;
}

@media (max-width:750px){
  .face:nth-child(1){
  transform:rotateY(0deg)  translateZ(85px);

  }
  .face:nth-child(2){
    transform:rotateY(51deg)  translateZ(85px);
  }
  .face:nth-child(3){
    transform:rotateY(104deg)  translateZ(85px);
  }
  .face:nth-child(4){
    transform:rotateY(156deg)  translateZ(85px);
  }
  .face:nth-child(5){
    transform:rotateY(208deg)  translateZ(85px);
  }
  .face:nth-child(6){
    transform:rotateY(258deg)  translateZ(85px);
  }
  .face:nth-child(7){
    transform:rotateY(308deg)  translateZ(85px);
  }

  .faceImg{
    transform: scale(.5);
  }
  .face{
    height: 5px;
    width: 90px;
  }
  .scene{
    height: 50px;
    margin-right:0 !important;
    width:100px;
  }
  .carouselShadow{
    margin-right: 10% !important;
  }
}
/*End media section================*/

@keyframes growing {
  from {transform:scale(0.9) skew(0deg) rotateX(84deg) scale(1.5);}
  to {transform:scale(1.5) skew(0deg)  rotateX(84deg) scale(1.5);}  
}

@media (max-width:750px){
  .carouselShadow{
    width:50px;
  }
}
.buttonsGroup button{
    text-align:center;
  background:transparent;
  border:none;
  color:rgba(53, 51, 51, 0.76);
  position: absolute !important;
  top: 200px;

}

button:hover, #theme-switch{
  cursor:pointer;
}

@keyframes floating{
  from {top:-10px;}
  to {top:10px;}
}
@keyframes circling{
  from{transform:rotateY(0deg)}
  to {transform: rotateY(360deg);}
}

@keyframes flicker{
  0% {opacity: .7; }
  24%{opacity: .7;}
  25%{opacity: 1; }
  30%{opacity: .7;}
  36%{opacity: .7;}
  37%{opacity: 1;}
  40%{opacity: 1;}
  43%{opacity: .7;}
}

@keyframes flicker2{
  0% {opacity: 1; }
  15%{opacity: 1;}
  16%{opacity: .7; }
  30%{opacity: .7;}
  31%{opacity: 1;}
  37%{opacity: 1;}
  38%{opacity: .7;}
}


.alert-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 800ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 800ms, transform 300ms;
}

.summary{
  margin-bottom:230px;
}


hr:first-child{
    box-shadow: black 0px 3px 6px;
}

hr:nth-child(2){
    box-shadow: 0px -2px 6px black;
}



#root{
  height:160vh;
  display: flex;
  flex-direction: column;
}



footer{
  flex-shrink: 0;
  max-height: 100px;
}

.image{
  background:url(../src/images/keysb.jpg);
  background-repeat: no-repeat;
  background-size: cover;
      -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
  background-position: 50% 28%;
}


.summaryScreen{
  margin-top:150px;
  margin-bottom:1300vh;
}



.charts{
  border: 1px solid lightgrey;
  box-shadow: 2px 4px 10px grey;
  border-radius: 10px;
  margin-bottom:20px;
}

#piechart{
  width:48% !important;
  height: 200px;
  padding:20px;
  margin:0 auto
}

@media(max-width:750px){
  #piechart{
    width: 80%;
  }
}

.window{
  box-shadow:0px 0px 27px darkgrey;
  padding: 25px 50px 50px 50px;
  border-radius: 5px;
}



.windowTrans-enter{
  opacity:0;
}

.windowTrans-enter-active{
  opacity: 0;
  transition: opacity 1000ms
}

#arrowSection{
  cursor:pointer;
}

.fadeOut {
  opacity: 0;
  transition: opacity 500ms;
}

.fadeIn {
  opacity: 1;
  transition: opacity 1s;
}


.image{
box-shadow:0px 4px 23px darkgrey;
}

.fixed{
position:fixed;
width:100%;
top:0;
left:1px;
}

/*Home Screen
=============*/
.seeWrapper{
  color:var(--yellow);
  transition: 500ms;
   cursor:pointer;
   display:inline;
}
.seeMore{
  font-size: 2em;
  text-align: right;
  font-weight:800;
  display:inline;
}

.seeWrapper:hover{
  color:var(--blue);
}

@media (max-width:750px){
  .seeMore{
    font-size: 1.4em;
  }
}
.fa-arrow-right{
  box-shadow: -4px 0px 5px 2px var(--main_black);
  border-radius:50%;
  
}
.shootingArrow{
  animation: shootingArrow 2.5s infinite ease-out;
}

@keyframes shootingArrow{
  from{ transform: translateX(0);}
  to {transform: translateX(150px);
  opacity: 0;}
}

.buttonCol{
  text-align:right;
}
.changeName{
  display:inline;
}

.newName{
  background: var(--yellowtoteal);
  -webkit-background-clip: text;
  color:transparent;
}

.name{
  text-shadow: none;
}
.card{
  text-align: center;
  background-color: transparent !important;
  height: 100px;
  font-weight:800;
  font-size:1.5em;
  border:none !important;
}
/*Skill Screen
===============*/

.responseWindow{  
  background-color:rgb(43, 43, 43);
  text-align:left;
  color:var(--teal);
  font-weight:700;
  max-height: 200px;
  overflow-y: scroll;
  padding-left: 5px;
  padding-top:10px;
  border-radius: 10px;
  border:solid .5px var(--text_color);
  box-shadow: 0px 0px 45px 5px whitesmoke,
  0px 0px 15px 1px red;
  margin-bottom:15px;
}

pre{
  white-space:pre-wrap;
}


.paymentEl{
  background-color: var(--grey);
  padding: 10px;
  border-radius:10px;
  box-shadow: 0px 8px 35px 8px var(--green);
}

label{
  font-weight: bold;
}

.apiButtonGroup{
  display:flex;
  justify-content: space-around;
}
/*About Screen
==================*/

blockquote{
  font-size: 3em;
  font-style: italic;
}

@media (max-width:1200px){
  blockquote{
    font-size: 2.5em;
  }
}
/*End AboutScreen*/

/*Footer
========*/

/*End */

/*PageNotFound
============*/

.superBig{
  font-size:6em;
  font-weight:900;
}

.modifyText:hover{
  color:var(--purple);
}

@media (max-width:768px){
  .superBig{
    font-size: 4em;
  }
}

.homePage{
  background-image: url("./images/rust_trans.png"), url('./images/python_bw.png');
  background-position: 75% 0%, 5% 45%;
  background-repeat: no-repeat, no-repeat;
}